<template>
  <v-dialog v-model="deleteDialog" persistent max-width="600px">
    <v-card>
      <v-card-text
        class="pb-0 pt-8"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-icon size="60" color="green" class="mb-5"
          >mdi-alert-circle-outline</v-icon
        >
        <div style="font-size: 20px">
          Are you sure do you want to delete?
        </div>
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="blue white" @click="onClose" class="white--text">
          Cancel
        </v-btn>
        <v-btn color="red white" @click="onConfirm" class="white--text">
          Yes, delete it!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
    onConfirm: {
      type: Function,
    },
  },
};
</script>
<style>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
